<template>
  <div class="pa-2">
    <h1 class="headline mb-1 font-weight-bold">
      監査法人Growth様ご協賛開始についてのお知らせ
    </h1>
    <p class="body-1">
      2024年8月1日
    </p><a
      href="https://www.growth-audit.jp/"
    ><img
      class="mt-3 mx-6 mb-5"
      :src="imageSrc"
      eager="eager"
      contain="contain"
      max-width="auto"
      width="90%"
    ></a>
    <h2 class="title mb-2 font-weight-black">
      ご協賛開始のお知らせ
    </h2>
    <p class="body-1 mb-1">
      会計士試験過去問コム（以下、本サイト）は、公認会計士受験生のために過去問を無料で解くことのできるWebアプリ、統計情報などを提供しております。
    </p>
    <p class="body-1 mb-1">
      本サイトは、主として個人の公認会計士が運営をしていますが、公認会計士受験生の試験勉強や受験情報の確保の充実に資することを目的としています。
    </p>
    <p class="body-1 mb-1">
      この度、本サイトは「監査法人Growth」様のご厚意により、協賛をいただくこととなりましたので、お知らせします。
    </p>
  </div>
</template>

<script>
export default {
  name: "NewsGrowth",
  computed: {
    imageSrc() {
      const breakpoint = this.$vuetify.breakpoint.name

      if (breakpoint === 'xs') {
        return require('@/assets/growth/sp.png')
      } else {
        return require('@/assets/growth/pc.png')
      }
    }
  }
}
</script>

<style scoped>

</style>
